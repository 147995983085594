import React from 'react';
import styled from '@emotion/styled';
import formatDate from 'date-fns/format';

import Layout from '../../../components/Layout';

import MEDIA_QUERIES from '../../../constants/media-queries';
import events from '../../../data/events';
import { parseDate } from '../../../utils/datetime';



const EventContentWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_QUERIES.M} {
    flex-direction: row;
  }
`;

const EventImage = styled.div`
  width: 100%;
  
  ${MEDIA_QUERIES.M} {
    width: 40%;
  }
`;

const EventDescription = styled.div`
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  ${MEDIA_QUERIES.M} {
    width: 60%;
    padding-left: 2em;
    padding-right: 2em;
  }
`;


export default function EventDetails(props) {
  const eventData = events['advantage-of-a-setting-sun'];
  const { title, location, date, slug, image, description } = eventData;

  const formattedDate = formatDate(parseDate(date), 'D MMMM YYYY', {});

  return (
    <Layout location={props.location}>
      <EventContentWrap>
        <EventImage>
          <img src={image} />
        </EventImage>
        <EventDescription>
          <h3>{title}</h3>
          <div>{formattedDate} / {location}</div>
          <p
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </EventDescription>
      </EventContentWrap>
    </Layout>
  );
}

